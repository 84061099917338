<template>
	<div class="moreStatics">
		
		<el-row :gutter="20" style="margin-top:10px">
   <el-col :span="12">
				<div>
					<div class="title-box"><span class="m-title">激活率统计</span></div>
					<div class="content-box">
					   <div>
						  <el-form ref="form" :model="form" inline class="hook  themed-form search-form" label-width="75px">
						 <el-form-item label="开卡公司:">
							
						 <el-select v-model="form.accountId" filterable @change="activationRate">
							<el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in accountLists" :label="item.accountName" :value="item.id" :key="index"></el-option>
                        </el-select>
						</el-form-item>
						  <el-form-item label="代理商:">
                           <el-select v-model="form.agentId"  filterable @change="activationRate">
                        <el-option label="全部" value=""></el-option>
                        <el-option  :label="agentThreeName" :value="loginAgentId"></el-option>
					    <el-option v-for="item in agentList" :key="item.id" :label="item.label" :value="item.value">
					</el-option>
					
				</el-select>
				</el-form-item>
				</el-form>
					   </div>
						<div class="rowone">
							<p>总激活</p>
								<p style="color: #09A2E3" >{{activeData.activeTotal}}</p>
							</div>
							<div class="rowone">
								<p>本月激活</p>
								<p style="color: #09A2E3" >{{activeData.activeCurMonth}}</p>
								</div>
							<div class="rowone">
								<p>昨天激活</p>
								<p style="color: #09A2E3" >{{activeData.activeLastDay}}</p>
							</div>
							
						
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import {getAccountList,} from '@/api/cardManagement/cardBill.js'
	import {list4active} from "@/api/welcome.js"
	 import {getAgentType} from "@/api/agentManagement.js"
	  import {getAgentList,} from '@/api/setMealManagment/setMealChange.js'
	export default{
		data(){
			return {
				form:{
                   accountId:"",
				   agentId:""
				},
				accountLists:[],
				agentList:[],
				activeData:{},
				loginAgentId:"",
				agentThreeName:"",
			}
		},
		created(){
			this.getAgentType()
			this._getAgentList()
			this._getAccountList()
			this.getList4active()
		},
		methods:{
			activationRate(val){
            this.getList4active()
			},
			getList4active(){
           list4active(this.form).then(res=>{
              console.log(res)
			  this.activeData= res 

		   })
			},
			
			 _getAccountList(){
                getAccountList().then((res) => {
                    this.accountLists = res
                })
            },
			 _getAgentList(){
                getAgentList(this.form).then((res) => {
                    this.agentList = res.map((item)=>{
                        return {
                            label:item.name,
                            value:item.id
                        }
                    })
                })
            },
			getAgentType(){
				getAgentType().then(res=>{
                    this.agentThreeName = res.agentThreeName
                    this.loginAgentId = res.agentId
                   
				})
            },
		}
	}
</script>

<style>
.moreStatics .rowone {
		display: flex;
		/* align-items: center; */
		justify-content: space-between;
		padding: 3px 0px;
	}
</style>
